export const TextStyle = {
  // Styles for the base style
  // Styles for the size variations
  sizes: {
    xs: {
      fontSize: "xs",
      letterSpacing: "0.025em",
      lineHeight: "1rem"
    }
  },
  // Styles for the visual style variations
  variants: {
    "sub-text": {
      color: "gray.500",
      fontWeight: "600"
    }
  },
  // The default `size` or `variant` values
  defaultProps: {}
};
