import { Box, Text, Container, Heading, chakra, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import bgClassRoom from "../../assets/images/academy_bg.png";
import CardClass from "../../components/CardClass";
export default function Xacademy() {
  const { t } = useTranslation();
  return (
    <>
      <Box
        h="100vh"
        p="5"
        bgImg={bgClassRoom}
        backgroundSize="cover"
        backgroundPosition="top left"
        className="text-center"
      >
        {" "}
        <Container textAlign="center" maxWidth="container.md" py={16}>
          <Heading textShadow="0 2px rgba(0,0,0,.4)" as="h4" color="white">
            #CriptoXpertXimbia
          </Heading>
          <Heading color="white" fontSize={{ base: "3xl", md: "5xl" }} lineHeight="none" letterSpacing="-0.015em">
            <chakra.span textShadow="0 4px 15px #000">Domine el mundo de las cryptos</chakra.span>
            <chakra.span
              backgroundImage="linear-gradient(97.53deg, #F7F2F4 5.6%, #7B61FF 59.16%, #46F4C8 119.34%)"
              backgroundClip="text"
              data-text={t("missions.title.2")}
              position="relative"
              zIndex="1"
              _after={{
                content: "attr(data-text)",
                position: "absolute",
                left: 0,
                top: 0,
                textShadow: "0 4px 15px #000",
                zIndex: -1,
                display: { base: "none", md: "inline" }
              }}
            >
              Conviértete en un especialista.
            </chakra.span>
          </Heading>
          <Text
            textShadow="0 3px rgba(0,0,0,.4)"
            fontSize={{ base: "xl", md: "2xl" }}
            letterSpacing="0.012em"
            mt={4}
            mx={4}
            color="white"
          >
            Se parte de nuestra comunidad educativa y aprenda sobre crypto y se un #XimbiaExpert
          </Text>
        </Container>
        <Container maxWidth="full" display="flex" flexDir="column" gap={12} px={{ base: 0, md: 6 }}>
          <Stack spacing={6}>
            <Text
              textShadow="0 3px rgba(0,0,0,.4)"
              fontSize={{ base: "xl", md: "2xl" }}
              letterSpacing="0.012em"
              mt={1}
              mx={1}
              color="white"
            >
              Disfruta de nuestras clases
            </Text>

            <CardClass />
          </Stack>
        </Container>
      </Box>
    </>
  );
}
