import { useEffect } from "react";

import { Box } from "@chakra-ui/react";
import type { AccountId, AssetId } from "@shapeshiftoss/caip";
import type { HistoryTimeframe } from "@shapeshiftoss/types";

import { calculatePercentChange } from "../../lib/charts";
import { Graph } from "../Graph/Graph";

type BalanceChartArgs = {
  assetId?: AssetId;
  accountId?: AccountId;
  timeframe: HistoryTimeframe;
  percentChange: number;
  setPercentChange: (percentChange: number) => void;
  isRainbowChart: boolean;
};

export const BalanceChart: React.FC<BalanceChartArgs> = ({
  // assetId,
  // accountId,
  // timeframe,
  percentChange,
  setPercentChange,
  isRainbowChart
}) => {
  // const { balanceChartData, balanceChartDataLoading } = useBalanceChartData({
  //   assetId,
  //   accountId,
  //   timeframe
  // });

  // const { total } = balanceChartData;
  const total = [{ price: 0, date: 55 }];
  useEffect(() => setPercentChange(calculatePercentChange(total)), [total, setPercentChange]);

  const color = percentChange > 0 ? "green.500" : "red.500";

  return (
    <Box height="350px">
      <Graph color={color} data={{}} loading={false} isLoaded={true} isRainbowChart={isRainbowChart} />
    </Box>
  );
};
