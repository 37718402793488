import { Box } from "@chakra-ui/react";

import UnderConstruccion from "../../components/UnderConstruccion";

export default function Xlaunchpad() {
  return (
    <>
      <Box p="5" className="text-center">
        <UnderConstruccion />
      </Box>
    </>
  );
}
