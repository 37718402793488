import { createIcon } from "@chakra-ui/react";

export const XimbiaIcon = createIcon({
  displayName: "XimbiaIcon",

  path: (
    <>
      <path
        fill="currentColor"
        d="M65.89 121.54c-21.31 23.58-42.85 43.23-62.58 69.01 24.27-22.77 45.66-36.09 71.38-55.96l-8.8-13.05zm147.65-63.61l.56-14.48-47.45-18.23c-20.26 16.47-39.78 33.98-58.63 52.42l10.33 25.46c30.62-22.82 58.67-34.34 95.19-45.17z"
      />
      <path
        fill="currentColor"
        d="M87.29 116.82L84.45 12.3l15.34 7.12c-6.87 59 20.85 111.37 66.41 160.33l-78.91-62.93z"
      />
      <path
        fill="currentColor"
        d="M169.18 182.07C115.72 130.08 90.04 69.63 87.94 2L17.59 66.49c44.62 49.16 95.8 86.52 151.59 115.58M62.85 118.02C41.07 142.12 20.17 167.45 0 193.8c24.81-23.27 50.16-45.46 76.45-65.77l-13.6-10.01z"
      />
      <path
        fill="currentColor"
        d="M220.27 44.71l-54.44-25.13c-20.71 16.83-40.65 34.72-59.92 53.57l10.56 26.02c32.75-21.86 67.12-40.47 103.8-54.46"
      />
      <path
        fill="currentColor"
        d="M409.27 132.08l-4.62-9.39h-17.39l1.76-3.67h13.94l-10.27-21.13-16.58 34.19h-4.4l18.49-37.86h4.99l18.49 37.86h-4.41zm-53.27 0V94.22h4.04v37.86H356zm-27.51-37.86c2.64 0 4.9.94 6.79 2.83 1.88 1.88 2.82 4.17 2.82 6.86 0 3.28-1.32 5.97-3.96 8.07 1.42.88 2.63 2.16 3.63 3.85s1.5 3.58 1.5 5.69c0 3.03-1 5.55-3.01 7.56s-4.5 3.01-7.48 3.01h-23.85V94.22h23.56zm-19.52 3.81v30.23h19c2.35 0 4.15-.6 5.39-1.8 1.25-1.2 1.87-2.83 1.87-4.88 0-2.2-.68-4.01-2.05-5.43-1.37-1.42-2.98-2.13-4.84-2.13h-13.21v-3.67h12.77c1.81 0 3.29-.64 4.44-1.91 1.15-1.27 1.72-2.81 1.72-4.62 0-1.76-.56-3.17-1.69-4.22-1.13-1.05-2.67-1.58-4.62-1.58h-18.78zm-22.67 5.58v28.47h-4.04v-23.33l4.04-5.14zm-36.98-9.39l16.29 19.88 15.92-19.88h4.77l-20.62 25.83-16.36-19.88v31.92h-4.03V94.22h4.03zm-27.36 37.86V94.22H226v37.86h-4.04zM176.1 94.22l9.54 10.71-2.49 3.01-12.4-13.72h5.35zm15.78 18.12l18.05 19.74h-5.36l-15.19-16.66-13.72 16.66h-5.14l31.25-37.86h5.14l-15.03 18.12z"
      />
    </>
  ),
  viewBox: "0 0 415.67 193.8"
});
