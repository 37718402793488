/* eslint-disable @typescript-eslint/no-explicit-any */
import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, IconButton, Flex } from "@chakra-ui/react";

import ConnectButton from "../ConnectButton";

const Header = ({ showSidebarButton = true, onShowSidebar }: any) => {
  return (
    <Flex p={4} color="white" justifyContent="center" w="100%">
      <Box flex="1">
        {showSidebarButton && (
          <IconButton
            icon={<HamburgerIcon w={5} h={5} />}
            colorScheme="white"
            variant="outline"
            size="sm"
            onClick={onShowSidebar}
            aria-label={""}
          />
        )}
      </Box>
      {!showSidebarButton && <Box display="flex" ml="auto" alignItems="center" justifyContent="center" h="30px"></Box>}
      <Box display="flex" ml="auto" alignItems="center" justifyContent="center" h="30px">
        <ConnectButton />
      </Box>
    </Flex>
  );
};

export default Header;
