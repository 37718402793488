import { Stack } from "@chakra-ui/react";

import XswapWidget from "../../components/Xswap";
import Portfolio from "./Portfolio";

export default function Xwallet() {
  return (
    <Stack alignItems="flex-start" p={10} spacing={4} mx="auto" direction={{ base: "column", xl: "row" }}>
      <Stack spacing={4} flex="1 1 0%" width="full">
        <Portfolio />
      </Stack>
      <Stack flex="1 1 0%" width="full" maxWidth={{ base: "full", xl: "sm" }} spacing={4}>
        <XswapWidget />
      </Stack>
    </Stack>
  );
}
