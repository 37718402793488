import { Box, Text } from "@chakra-ui/react";

export default function Xadmin() {
  return (
    <>
      <Box p="5" className="text-center">
        <Text align="center">Xadmin</Text>
      </Box>
    </>
  );
}
