import React, { useEffect, useRef } from "react";

import {
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  DrawerContent,
  VStack,
  useColorModeValue,
  useColorMode,
  Box
} from "@chakra-ui/react";

import { XimbiaIcon } from "../XimbiaIcon";
import { NavBar } from "./NavBar";
interface SidebarProps {
  isOpen: boolean;
  variant?: string;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, variant, onClose }) => {
  const { colorMode } = useColorMode();
  const bgColor = useColorModeValue("white", "blackAlpha.300");
  const shadow = useColorModeValue("lg", "none");
  const activeRef = useRef<HTMLButtonElement | null>(null);
  const basecolor = colorMode === "light" ? "white" : "white";
  useEffect(() => {
    if (activeRef.current) {
      activeRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }, [location]);

  return variant === "sidebar" ? (
    <Box
      position="fixed"
      left={0}
      p={3}
      w="300px"
      bg={bgColor}
      boxShadow={shadow}
      overflow="auto"
      top={0}
      h="100%"
      borderRight="1px solid rgba(255, 255, 255, 0.16)"
      bgGradient="radial-gradient(circle, rgba(57,0,77,1) 0%, rgba(0,0,0,1)139%)"
    >
      <VStack>
        <Box w="100%" display="flex" justifyContent="center">
          <XimbiaIcon mt={5} w="100%" h={20} color={basecolor} />
        </Box>
        <NavBar />
      </VStack>
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent bgGradient="radial-gradient(circle, rgba(57,0,77,1) 0%, rgba(0,0,0,1)139%)">
          <DrawerCloseButton />

          <DrawerBody>
            <VStack>
              <Box w="100%" display="flex" justifyContent="center">
                <XimbiaIcon mt={5} w="100%" h={20} color={basecolor} />
              </Box>
            </VStack>
            <NavBar />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Sidebar;
