/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from "react";

import { useContract, useContractRead } from "@thirdweb-dev/react";

//import { ethers } from "ethers";

const pancakeSwapContract = "0x10ED43C718714eb63d5aA57B78B54704E256024E".toLowerCase();
const BNBTokenAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; //BNB
const XMBTokenAddress = "0x3bdeECae844b96A133F98e54e36eB85414ffe5c9"; //XMB
const USDTTokenAddress = "0x55d398326f99059fF775485246999027B3197955"; //USDT
const pancakeSwapAbi = [
  {
    inputs: [
      { internalType: "uint256", name: "amountIn", type: "uint256" },
      { internalType: "address[]", name: "path", type: "address[]" }
    ],
    name: "getAmountsOut",
    outputs: [{ internalType: "uint256[]", name: "amounts", type: "uint256[]" }],
    stateMutability: "view",
    type: "function"
  }
];

export const useGetPriceTokenToBNB = (token: string = XMBTokenAddress, symbol = "XMB") => {
  const [balance, setBalance] = useState("Price " + symbol + ": " + 0 + " USD");
  const scpancakeSwapContract = useContract(pancakeSwapContract, pancakeSwapAbi);
  const bnbToSell = "1000000000000000000";

  const tokenXimbiaToBNB = [token, BNBTokenAddress];
  const tokenBNBToUSDT = [BNBTokenAddress, USDTTokenAddress];

  const calcXMBPrice = useContractRead(scpancakeSwapContract.contract, "getAmountsOut", [bnbToSell, tokenXimbiaToBNB]);

  const calcBNBPrice = useContractRead(scpancakeSwapContract.contract, "getAmountsOut", [bnbToSell, tokenBNBToUSDT]);

  useEffect(() => {
    if (calcBNBPrice && calcXMBPrice?.data?.length > 1 && calcBNBPrice?.data?.length > 1) {
      const g = calcXMBPrice?.data[1] * calcBNBPrice?.data[1];
      const convertedNumber = (g / 1e36).toFixed(3);
      setBalance("Price " + symbol + ": " + convertedNumber + " USD");
    }
  }, [calcXMBPrice, calcBNBPrice]);
  return balance;
};
