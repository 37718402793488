/* eslint-disable @typescript-eslint/no-empty-interface */
import { FunctionComponent } from "react";

import { Button, Center, Container, Heading, Text, VStack, useColorMode } from "@chakra-ui/react";

import { XimbiaIcon } from "../XimbiaIcon";

interface HeroSectionProps {}

export const Home: FunctionComponent<HeroSectionProps> = () => {
  //const lightMode = isInverted ? 'white' : 'gray.800'
  //const darkMode = isInverted ? 'gray.800' : 'white'
  //const iconFill = fill || useColorModeValue(lightMode, darkMode)
  const { colorMode } = useColorMode();
  const basecolor = colorMode === "light" ? "white" : "white";
  return (
    <Container maxW="container.lg">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Container maxW="container.md" textAlign="center">
            <XimbiaIcon mb={5} mt={5} w="100%" h={20} color={basecolor} />
            <Heading size="xl" mb={4} color="gray.500">
              Decentralized Exchange on Binance Smart Chain and Arbitrum
            </Heading>

            <Text fontSize="xl" color="gray.500">
              A revolutionary exchange for a revolutionary network,Ximbia.
            </Text>

            <Button
              mt={8}
              onClick={() => {
                window.open("/dashboard/xswap", "_self");
              }}
            >
              Launch app
            </Button>
          </Container>
        </VStack>
      </Center>
    </Container>
  );
};
