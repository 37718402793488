import { Flex, Skeleton, useColorModeValue } from "@chakra-ui/react";

import { Card } from "../../components/Card/Card";
import { CircularProgress } from "../../components/CircularProgress/CircularProgress";
import { Text } from "../../components/Text";

type StatCardProps = {
  percentage: number;
  value: string;
  label: string;
  color?: string;
  onClick?: () => void;
  isLoading?: boolean;
};

const BreakdownCard: React.FC<StatCardProps> = ({ percentage, value, label, color, onClick, isLoading }) => {
  const hoverBg = useColorModeValue("gray.600", "gray.750");
  return (
    <Card flex={1} cursor="pointer" onClick={onClick} _hover={{ bg: hoverBg }}>
      <Card.Body display="flex" gap={4} alignItems="center">
        <CircularProgress isIndeterminate={isLoading} value={percentage} color={color ? color : "blue.500"} />
        <Flex direction="column">
          <Text color="gray.500" fontWeight="medium" translation={label} />
          <Skeleton isLoaded={!isLoading}>
            {/* <Amount.Fiat fontWeight="bold" fontSize="xl" value={value} /> */}
            {value}
          </Skeleton>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export const PortfolioBreakdown = () => {
  return (
    <Flex gap={{ base: 0, xl: 6 }} flexDir={{ base: "column", md: "row" }}>
      <BreakdownCard value={`$0.00`} percentage={0} label="Saldo de la Billetera BNB" />
      <BreakdownCard value={`$0.00`} percentage={0} label="Saldo de la Billetera BTIC" color="green.500" />
      <BreakdownCard value={`$0.00`} percentage={0} label="Saldo de la Billetera BTIC" color="green.500" />
    </Flex>
  );
};
