import type { ButtonGroupProps } from "@chakra-ui/react";
import { HistoryTimeframe } from "@shapeshiftoss/types";

import { Radio } from "../Radio/Radio";

type TimeControlsProps = {
  onChange: (arg: HistoryTimeframe) => void;
  defaultTime: HistoryTimeframe;
  buttonGroupProps?: ButtonGroupProps;
};

export const TimeControls = ({ onChange, defaultTime, buttonGroupProps }: TimeControlsProps) => {
  const options = Object.freeze([
    { value: HistoryTimeframe.HOUR, label: "1H" },
    { value: HistoryTimeframe.DAY, label: "24H" },
    { value: HistoryTimeframe.WEEK, label: "1W" },
    { value: HistoryTimeframe.MONTH, label: "1M" },
    { value: HistoryTimeframe.YEAR, label: "1Y" },
    { value: HistoryTimeframe.ALL, label: "all" }
  ]);
  return <Radio options={options} defaultValue={defaultTime} onChange={onChange} buttonGroupProps={buttonGroupProps} />;
};
