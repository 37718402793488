/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Heading, Text, Center } from "@chakra-ui/react";
import { LiFiWidget } from "@lifi/widget";

import p2pbg from "../../assets/images/bg_sx_swap.png";
//import Chart from "../../components/ChartDEX/Chart";
import { PageContainer } from "../../components/PageContainer";
const widgetConfig: any = {
  fromChain: 56,
  toChain: 56,
  fromToken: "0x0000000000000000000000000000000000000000",
  toToken: "0x3bdeECae844b96A133F98e54e36eB85414ffe5c9",
  disableLanguageDetector: false,
  tokens: {
    featured: [
      {
        address: "0x3bdeECae844b96A133F98e54e36eB85414ffe5c9",
        symbol: "XMB",
        decimals: 18,
        chainId: 56,
        name: "XIMBIA",
        logoURI: "https://minio-s3.caprover.snotrasys.com/ximbia/moneda-ximbia.png"
      }
    ]
  },
  variant: "expandable",
  subvariant: "split",
  hiddenUI: ["poweredBy", "appearance", "language"],
  routePriority: "FASTEST",
  appearance: "light",

  containerStyle: {
    border: "1px solid rgb(234, 234, 234)",
    borderRadius: "8px",
    background: "red"
  },
  insurance: true,
  languages: {
    default: "es",
    allow: ["es", "pt", "th", "tr", "id", "fr", "en", "bn", "de", "it", "ko", "uk", "vi", "zh"]
  },
  theme: {
    palette: {
      primary: { main: "#9900d1" },
      secondary: { main: "#F5B5FF" }
    },
    shape: {
      borderRadius: 8,
      borderRadiusSecondary: 8
    },
    MuiAppBar: {
      styleOverrides: { background: "red" }
    }
  }
};

export default function Xswap() {
  return (
    <>
      <Box h="100vh" p="5" bgImg={p2pbg} backgroundSize="cover" backgroundPosition="top left" className="text-center">
        <PageContainer pt={{ base: 8, md: "1rem" }} pb={{ base: 0, md: "7.5rem" }}>
          <Center>
            <Heading
              fontSize={{ base: "4xl", xl: "6xl" }}
              lineHeight="1em"
              letterSpacing="-0.05em"
              color="whiteAlpha.900"
              className="text-center center"
              mb={5}
            >
              Trade instantly
              <br />
              <Text
                as="span"
                background="linear-gradient(97.53deg, #F687B3 5.6%, #7B61FF 59.16%, #16D1A1 119.34%)"
                backgroundClip="text"
              >
                with tokens
              </Text>
            </Heading>
          </Center>

          <LiFiWidget integrator="XIMBIA DAO" config={widgetConfig} />
        </PageContainer>
      </Box>
    </>
  );
}
