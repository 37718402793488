/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Center, Text, SimpleGrid, Stack, Button } from "@chakra-ui/react";
import { useParams } from "react-router";
import { TiChevronLeft } from "react-icons/ti";

import bgClassRoom from "../../assets/images/academy_bg.png";
import { PageContainer } from "../../components/PageContainer";

export default function ClassRoom(props: any) {
  const { id } = useParams();
  console.log("props", props);
  console.log("id", id);

  return (
    <Box
      h="100vh"
      p="5"
      bgImg={bgClassRoom}
      backgroundSize="cover"
      backgroundPosition="top left"
      className="text-center"
    >
      <PageContainer pt={{ base: 8, md: "1rem" }} pb={{ base: 0, md: "7.5rem" }}>
        <Center>
          <SimpleGrid columns={1} spacing={10}>
            <Stack direction="row" spacing={4}>
              <Button
                as="a"
                href="/dashboard/xacademy/"
                leftIcon={<TiChevronLeft />}
                colorScheme="pink"
                variant="solid"
              >
                Volver
              </Button>{" "}
              <Text
                color={"green.500"}
                textTransform={"uppercase"}
                fontWeight={800}
                fontSize={"xl"}
                letterSpacing={1.1}
              >
                Ximbia Academy
              </Text>{" "}
            </Stack>

            <Box>
              <iframe
                width="800"
                height="600"
                src={"https://www.youtube.com/embed/" + id + "?autoplay=1"}
                title="Ximbia Academy"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          </SimpleGrid>
        </Center>
      </PageContainer>
    </Box>
  );
}
