import { useCallback, useState } from "react";

import { Button, Flex, Skeleton, Stack, Stat, StatArrow, StatNumber, Switch } from "@chakra-ui/react";
import { HistoryTimeframe } from "@shapeshiftoss/types";

import { BalanceChart } from "../../components/BalanceChart/BalanceChart";
import { Card } from "../../components/Card/Card";
import { TimeControls } from "../../components/Graph/TimeControls";
import { Text } from "../../components/Text";
import { PortfolioBreakdown } from "./PortfolioBreakdown";
const Portfolio = () => {
  const [timeframe] = useState(HistoryTimeframe.DAY);
  const [isRainbowChart, setIsRainbowChart] = useState(false);
  const [percentChange, setPercentChange] = useState(30);
  const toggleChartType = useCallback(() => setIsRainbowChart(!isRainbowChart), [isRainbowChart]);
  const loading = false;
  const isLoaded = !loading;
  function handleTimeframeChange(params: any) {
    console.log("params", params);
  }

  return (
    <Stack spacing={6} width="full">
      <Card variant="footer-stub">
        <Card.Header
          display="flex"
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems="center"
          textAlign={{ base: "center", md: "inherit" }}
          width="full"
          flexDir={{ base: "column", md: "row" }}
        >
          <Button size="sm" flexDirection="row" onClick={toggleChartType} variant="outline">
            <Text translation="Total" />
            <Switch isChecked={isRainbowChart} pointerEvents="none" mx={2} size="sm" />
            <Text translation="Arcoiris" />
          </Button>
          <Skeleton isLoaded={isLoaded} display={{ base: "none", md: "block" }}>
            <TimeControls defaultTime={timeframe} onChange={handleTimeframeChange} />
          </Skeleton>
        </Card.Header>
        <Flex flexDir="column" justifyContent="center" alignItems="center">
          <Card.Heading as="div" color="gray.500">
            <Skeleton isLoaded={isLoaded}>
              <Text translation="Valor neto" />
            </Skeleton>
          </Card.Heading>
          <Card.Heading as="h2" fontSize="4xl" lineHeight="1">
            <Skeleton isLoaded={isLoaded}>{/* <Amount.Fiat value={totalBalance} /> */} $ 0.00 USD</Skeleton>
          </Card.Heading>
          {isFinite(percentChange) && (
            <Skeleton mt={2} isLoaded={!!percentChange}>
              <Stat display="flex" justifyContent={{ base: "center", md: "flex-start" }}>
                <StatNumber fontSize="md" display="flex" alignItems="center">
                  <StatArrow type={percentChange > 0 ? "increase" : "decrease"} />
                  {/* <Amount.Percent value={percentChange * 0.01} /> */}
                  {percentChange * 0.01}%
                </StatNumber>
              </Stat>
            </Skeleton>
          )}
        </Flex>
        <BalanceChart
          timeframe={timeframe}
          percentChange={percentChange}
          setPercentChange={setPercentChange}
          isRainbowChart={isRainbowChart}
        />
        <Skeleton isLoaded={isLoaded} display={{ base: "block", md: "none" }}>
          <TimeControls
            onChange={handleTimeframeChange}
            defaultTime={timeframe}
            buttonGroupProps={{
              display: "flex",
              width: "full",
              justifyContent: "space-between",
              px: 6,
              py: 4
            }}
          />
        </Skeleton>
      </Card>
      <PortfolioBreakdown />
    </Stack>
  );
};

export default Portfolio;
