import { LiFiWidget } from "@lifi/widget";

const widgetConfig: any = {
  fromChain: 56,
  toChain: 56,
  fromToken: "0x0000000000000000000000000000000000000000",
  toToken: "0x3bdeECae844b96A133F98e54e36eB85414ffe5c9",
  disableLanguageDetector: false,
  tokens: {
    featured: [
      {
        address: "0x3bdeECae844b96A133F98e54e36eB85414ffe5c9",
        symbol: "XMB",
        decimals: 18,
        chainId: 56,
        name: "XIMBIA",
        logoURI: "https://minio-s3.caprover.snotrasys.com/ximbia/moneda-ximbia.png"
      }
    ]
  },
  variant: "expandable",
  subvariant: "split",
  hiddenUI: ["poweredBy", "appearance", "language", "walletMenu"],
  routePriority: "FASTEST",
  appearance: "light",

  containerStyle: {
    border: "1px solid rgb(234, 234, 234)",
    borderRadius: "8px",
    background: "red"
  },
  insurance: true,
  languages: {
    default: "es",
    allow: ["es", "pt", "th", "tr", "id", "fr", "en", "bn", "de", "it", "ko", "uk", "vi", "zh"]
  },
  theme: {
    palette: {
      primary: { main: "#9900d1" },
      secondary: { main: "#F5B5FF" }
    },
    shape: {
      borderRadius: 8,
      borderRadiusSecondary: 8
    },
    MuiAppBar: {
      styleOverrides: { background: "red" }
    }
  }
};
const XswapWidget = () => {
  return (
    <>
      <LiFiWidget integrator="XIMBIA DAO" config={widgetConfig} />
    </>
  );
};

export default XswapWidget;
