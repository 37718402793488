/* eslint-disable no-console */
import { useEffect, useState } from "react";

import { Box, Center, Heading, Text, Stack, Avatar, useColorModeValue, Image, SimpleGrid } from "@chakra-ui/react";
import axios from "axios";
import { BsArrowUpRight } from "react-icons/bs";
interface Video {
  id: string;
  snippet: {
    title: string;
    description: string;
    channelTitle: string;
    publishedAt: string;
    resourceId: {
      videoId: string;
    };
    thumbnails: {
      default: {
        url: string;
      };
      standard: {
        url: string;
      };
      high: {
        url: string;
      };
    };
  };
}

export default function CardClass() {
  const [videos, setVideos] = useState<Video[]>([]);
  useEffect(() => {
    const fetchPlaylistVideos = async () => {
      try {
        const playlistId = "PLVhooQufzG5W0CkV-popWsla8UtdIEpfd";
        const apiKey = "AIzaSyD__LoR164eM683cIa0EKI7RNikK62QT-E";
        const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=${apiKey}`;
        const response = await axios.get(url);
        setVideos(response.data.items);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlaylistVideos();
  }, []);
  if (videos.length === 0) {
    return <div>Cargando...</div>;
  }
  return (
    <div>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3, xl: 3 }} spacingX="40px" spacingY="20px">
        {" "}
        {videos.map((video) => (
          <Center py={6} pt={0} key={video?.id}>
            <a href={"/dashboard/xacademy/" + video?.snippet?.resourceId?.videoId} title={video?.snippet?.title}>
              <Box
                maxW={"100%"}
                w={"full"}
                bg={useColorModeValue("white", "gray.900")}
                boxShadow={"2xl"}
                rounded={"md"}
                p={6}
                overflow={"hidden"}
              >
                <Box h={"auto"} bg={"gray.100"} mt={-9} mx={-6} mb={6} pos={"relative"}>
                  <Image src={video?.snippet?.thumbnails?.standard?.url} h="100%" w="100%" />
                </Box>
                <Stack>
                  <Text
                    color={"green.500"}
                    textTransform={"uppercase"}
                    fontWeight={800}
                    fontSize={"sm"}
                    letterSpacing={1.1}
                  >
                    Ximbia Academy
                  </Text>
                  <Heading color={useColorModeValue("gray.700", "white")} fontSize={"xl"} fontFamily={"body"}>
                    {video?.snippet?.title}
                  </Heading>
                </Stack>
                <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                  <Avatar src={video?.snippet?.thumbnails?.standard?.url} />
                  <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                    <Text fontWeight={600}>{video?.snippet?.channelTitle}</Text>
                    <Text color={"gray.900"}>{video?.snippet?.publishedAt}</Text>
                  </Stack>
                  <BsArrowUpRight />
                </Stack>
              </Box>
            </a>
          </Center>
        ))}
      </SimpleGrid>
    </div>
  );
}
