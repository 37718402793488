import { useState } from "react";

import { useBreakpointValue, Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
const smVariant = { navigation: "drawer", navigationButton: true };
const mdVariant = { navigation: "sidebar", navigationButton: false };

export default function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return (
    <>
      <Sidebar variant={variants?.navigation} isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <Box ml={variants?.navigationButton ? 0 : 300}>
        <Header showSidebarButton={variants?.navigationButton} onShowSidebar={toggleSidebar} />
        <Box p="0">
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
