import { Box } from "@chakra-ui/react";

import Book from "../../components/ChartDEX/Book";

export default function XInfo() {
  return (
    <>
      <Box p="5" className="text-center">
        <Book />
      </Box>
    </>
  );
}
