/* eslint-disable import/named */
import { Box } from "@chakra-ui/react";

import bgClassRoom from "../../assets/images/gneric_bg.png";
import { Home } from "../../components/Home";
export default function XHome() {
  return (
    <>
      <Box
        p="0"
        h="100vh"
        className="text-center"
        bgImg={bgClassRoom}
        backgroundSize="cover"
        backgroundPosition="top left"
      >
        <Home />
      </Box>
    </>
  );
}
