import React from "react";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Binance } from "@thirdweb-dev/chains";
import {
  ThirdwebProvider,
  rainbowWallet,
  trustWallet,
  safeWallet,
  metamaskWallet,
  coinbaseWallet
} from "@thirdweb-dev/react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import "./i18n";
import IndexWeb from "./pages";
import XHome from "./pages/Home";
import PageNotFound from "./pages/pagenotfound";
import "./index.css";
import Xacademy from "./pages/xacademy";
import ClassRoom from "./pages/xacademy/ClassRoom";
import Xadmin from "./pages/xadmin";
import Xassets from "./pages/xassets";
import Xburn from "./pages/xburn";
import XInfo from "./pages/xinfo";
import Xlaunchpad from "./pages/xlaunchpad";
import Xlottery from "./pages/xlottery";
import Xp2p from "./pages/xp2p";
import Xpredictions from "./pages/xpredictions";
import Xstake from "./pages/xstake";
import Xswap from "./pages/xswap";
import Xwallet from "./pages/xwallet";
import { theme } from "./theme/theme";
const root = createRoot(document.getElementById("root") as HTMLElement);

const router = createBrowserRouter([
  {
    path: "/*",
    element: <IndexWeb />,
    errorElement: <PageNotFound />,

    children: [
      {
        path: "",
        element: <XHome />
      },
      {
        path: "nosotros",
        element: <p>ssl</p>
      }
    ]
  },
  {
    path: "dashboard/*",
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: "xacademy",
        element: <Xacademy />
      },
      {
        path: "xadmin",
        element: <Xadmin />
      },
      {
        path: "xassets",
        element: <Xassets />
      },
      {
        path: "xburn",
        element: <Xburn />
      },
      {
        path: "xlaunchpad",
        element: <Xlaunchpad />
      },
      {
        path: "xlottery",
        element: <Xlottery />
      },
      {
        path: "xp2p/*",
        element: <Xp2p />
      },
      {
        path: "xpredictions",
        element: <Xpredictions />
      },
      {
        path: "xstake",
        element: <Xstake />
      },

      {
        path: "xswap/*",
        element: <Xswap />
      },
      {
        path: "xacademy/:id",
        element: <ClassRoom />
      },
      {
        path: "Xtools/*",
        element: <XInfo />
      },

      {
        path: "*",
        element: <Xwallet />
      }
    ]
  }
]);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ThirdwebProvider
        activeChain={Binance}
        supportedWallets={[metamaskWallet(), coinbaseWallet(), rainbowWallet(), trustWallet(), safeWallet()]}
      >
        <RouterProvider router={router} />
      </ThirdwebProvider>
    </ChakraProvider>{" "}
  </React.StrictMode>
);
