import { Box, Flex, Heading, Card, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import p2pbg from "../../assets/images/gneric_bg.png";
import { PageContainer } from "../../components/PageContainer";
export default function Xp2p() {
  const { t } = useTranslation();
  return (
    <Box
      m="0"
      p="5"
      className="text-center"
      h="100vh"
      bgImg={p2pbg}
      backgroundSize="cover"
      backgroundPosition="top left"
    >
      <PageContainer pt={{ base: 8, md: "7.5rem" }} pb={{ base: 0, md: "7.5rem" }}>
        <Flex
          flexDir={{ base: "column", xl: "row" }}
          alignItems="center"
          justifyContent="space-between"
          width="full"
          gap={6}
        >
          <Flex
            flexDir="column"
            flex={1}
            gap={4}
            alignItems={{ base: "center", xl: "flex-start" }}
            textAlign={{ base: "center", xl: "left" }}
          >
            <Heading
              fontSize={{ base: "4xl", xl: "6xl" }}
              lineHeight="1em"
              letterSpacing="-0.05em"
              color="whiteAlpha.900"
            >
              {t("buyPage.title.first")}{" "}
              <Text
                as="span"
                background="linear-gradient(97.53deg, #F687B3 5.6%, #7B61FF 59.16%, #16D1A1 119.34%)"
                backgroundClip="text"
              >
                {t("buyPage.title.second")}
              </Text>
            </Heading>
            <Text fontSize="lg" color="whiteAlpha.900">
              {t("buyPage.body")}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {t("buyPage.disclaimer")}
            </Text>
          </Flex>
          <Box flexBasis="400px">
            <Card mx={{ base: -4, md: 0 }}>
              <iframe
                src="https://buy.onramper.com/?themeName=light&containerColor=ffffff&primaryColor=9900d1ff&secondaryColor=ffffff&cardColor=f6f7f9&primaryTextColor=141519&secondaryTextColor=6b6f80&borderRadius=0.75&wgBorderRadius=1"
                title="Ximbia DAO"
                height="630px"
                width="420px"
                allow="accelerometer; autoplay; camera; gyroscope; payment"
              />{" "}
            </Card>
          </Box>
        </Flex>
      </PageContainer>
    </Box>
  );
}
