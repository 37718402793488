/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default-member */
// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEn from "./assets/translations/en/main.json";
import translationEs from "./assets/translations/es/main.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      es: {
        translations: translationEs
      },
      en: {
        translations: translationEn
      }
    },
    fallbackLng: "es",
    lng: "es",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: ".", // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
