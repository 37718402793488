import { Box, Heading, Text, Image, Center } from "@chakra-ui/react";

import home3 from "../../assets/images/home3.png";
export default function UnderConstruccion() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Center>
        {" "}
        <Image src={home3} boxSize="600px" alt="Dan Abramov" objectFit="cover" />
      </Center>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        This area is under construction
      </Heading>
      <Text color={"gray.500"}>Very soon it will be available to all our users</Text>
    </Box>
  );
}
