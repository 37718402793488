/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from "react";

import type { StackProps } from "@chakra-ui/react";
import { Stack, Text, useColorModeValue, Image } from "@chakra-ui/react";
import { AiOutlineBarChart } from "react-icons/ai";
import { BiBookBookmark, BiGitCompare, BiLayerPlus, BiSolidFlame, BiWallet, BiSolidLock } from "react-icons/bi";
import {
  BsFillRocketTakeoffFill,
  BsFillSuitClubFill,
  BsFillTrophyFill,
  BsFillHouseHeartFill,
  BsCreditCardFill
} from "react-icons/bs";
import { RiExchangeDollarLine, RiNftFill } from "react-icons/ri";
import { GiFarmTractor } from "react-icons/gi";
import { SiFuturelearn } from "react-icons/si";
import { FaBitcoin } from "react-icons/fa";

import { Link } from "react-router-dom";

import bioticCoin from "../../assets/images/biotic.png";
import ximbiaCoin from "../../assets/images/moneda-ximbia.png";
import { useGetPriceTokenToBNB } from "../../hooks/useGetPriceTokenToBNB";
import { Audited } from "../Banners/Audit";
import { MainNavLink } from "./MainNavLink";

type NavBarProps = {
  isCompact?: boolean;
  onClick?: () => void;
} & StackProps;

export const NavBar = ({ isCompact, onClick, ...rest }: NavBarProps) => {
  const groupColor = useColorModeValue("gray.400", "gray.600");

  const balanceXMB = useGetPriceTokenToBNB();
  const balanceBTIC = useGetPriceTokenToBNB("0x9945f6221Efee040a12054217504cBC230f0ACC9", "BTIC");

  const routes = [
    {
      label: "Xwallet"
    },
    {
      path: "/dashboard",
      label: "XWallet",
      icon: <BiWallet />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/xp2p",
      label: "Buy Crypto",
      icon: <BsCreditCardFill />,
      withR: true,
      soon: false
    },

    {
      path: "/dashboard/XTools",
      label: "XTools",
      icon: <AiOutlineBarChart />,
      withR: true,
      soon: false
    },

    {
      path: "/dashboard/xswap",
      label: "Xwap / XBridge",
      icon: <BiGitCompare />,
      withR: true,
      soon: false
    },
    {
      path: "https://www.ximbia.io/stake",
      label: "Xtake",
      icon: <BiLayerPlus />,
      withR: true,
      soon: false
    },
    {
      path: "https://biofarm.ximbia.io/",
      label: "Bio Farm",
      icon: <GiFarmTractor />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/xacademy",
      label: "XAcademy",
      icon: <BiBookBookmark />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/xp2p",
      label: "XP2P",
      icon: <RiExchangeDollarLine />,
      withR: true,
      soon: true
    },
    {
      path: "/dashboard/xlaunchpad",
      label: "XLaunchpad ",
      icon: <BsFillRocketTakeoffFill />,
      withR: true,
      soon: true
    },
    {
      path: "/dashboard/",
      label: "XNFT",
      icon: <RiNftFill />,
      withR: true,
      soon: true
    },
    {
      path: "/dashboard/",
      label: "XToken",
      icon: <FaBitcoin />,
      withR: true,
      soon: true
    },

    {
      path: "/dashboard/",
      label: "Ximbia RET",
      icon: <BsFillHouseHeartFill />,
      withR: true,
      soon: true
    },
    {
      path: "/dashboard/",
      label: "XFuturos",
      icon: <SiFuturelearn />,
      withR: true,
      soon: true
    },
    {
      path: "/",
      label: "XLocks",
      icon: <BiSolidLock />,
      withR: true,
      soon: true
    },
    {
      path: "/dashboard/xburn",
      label: "XBurn",
      icon: <BiSolidFlame />,
      withR: true,
      soon: true
    },
    {
      label: "XGames"
    },
    {
      path: "https://prediction.ximbia.io/",
      label: "XPredictions",
      icon: <BsFillTrophyFill />,
      withR: true,
      soon: false
    },
    {
      path: "/dashboard/xlottery",
      label: "XLottery",
      icon: <BsFillSuitClubFill />,
      withR: true,
      soon: true
    },
    {
      label: "Audit"
    }
  ];

  const menu = useMemo(() => {
    return routes.map((r, index) => (
      <Stack key={index}>
        {r.withR ? (
          <>
            <MainNavLink
              leftIcon={r.icon}
              as={Link}
              to={r.path}
              label={r.label}
              href={r.path}
              size="lg"
              soon={r.soon}
            ></MainNavLink>{" "}
          </>
        ) : (
          <>
            <Text
              px={4}
              color={groupColor}
              fontSize="xs"
              textTransform="uppercase"
              fontWeight="bold"
              letterSpacing="wider"
              display={{ base: isCompact ? "none" : "block", "2xl": "block" }}
            >
              {" "}
              {r.label}
            </Text>
          </>
        )}
      </Stack>
    ));
  }, [routes]);

  return (
    <Stack width="full" mt={10} flex="1 1 0%" spacing={2} {...rest}>
      {menu}
      <Audited />

      <Stack width="full" mt={4} spacing={0}>
        <MainNavLink
          isCompact={isCompact}
          size="sm"
          label={`${balanceXMB}`}
          leftIcon={<Image src={ximbiaCoin} w="30px" h="30px" />}
          data-test="navigation-settings-button"
        />
        <MainNavLink
          isCompact={isCompact}
          size="sm"
          label={`${balanceBTIC}`}
          leftIcon={<Image src={bioticCoin} w="30px" h="30px" />}
          data-test="navigation-settings-button"
        />
      </Stack>
    </Stack>
  );
};
