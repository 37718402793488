import { ConnectWallet } from "@thirdweb-dev/react";

function ConnectButton() {
  return (
    <ConnectWallet
      theme="light"
      btnTitle="Connect Wallet"
      className="btn-mtks"
      style={{ height: "40px", borderRadius: "8px", border: "1px solid #502c92" }}
    />
  );
}
export default ConnectButton;
