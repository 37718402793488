import { Box, Text } from "@chakra-ui/react";

export default function Xassets() {
  return (
    <>
      <Box p="5" className="text-center">
        <Text align="center">404 - Page Not Found</Text>
      </Box>
    </>
  );
}
